import axios from 'axios'
import { Notification, MessageBox, Message } from 'element-ui'
import errorCode from '@/utils/errorCode'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const token = window.sessionStorage.getItem('token');
  config.headers['satoken'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
  if ((config.method === 'post' || config.method === 'put') && !(config.data instanceof FormData)) {
    config.data = config.data
    // {
    //   ...config.data,
    //   // systemSource:systemSource
    // }
  }
  if ((config.method === 'get' || config.method === 'delete') && !(config.data instanceof FormData)) {
    config.params = {
      ...config.params,
      //  systemSource:systemSource
    }
  }
  if (config.data instanceof FormData) {
    delete config.headers['Content-Type'];
    config.params = {
      ...config.params
    }
  }
  //将参数放到回话里面了
  console.log(config.method)
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || '200';
  // 获取错误信息
  const msg = errorCode[code] || res.data.message || errorCode['default']

  if (code === '401') {
    MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }
    ).then(() => {
      store.dispatch('LogOut').then(() => {
        location.href = '/index';
      })
    })
  } else if (code === '500') {
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject(new Error(msg))
  } else if (code !== '200') {
    Notification.error({
      title: msg
    })
    return Promise.reject('error')
  } else {
    return res.data
  }
},
  error => {
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = error.response.data.message;
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
