import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const commonList = [
    { path: '/', redirect: '/login' },
    // 
    { path: '/login', component: () => import('@/components/login') },
    { path: '/home', component: () => import('@/components/home'), children: [] },
    { path: '/register', component: () => import('@/components/register') },
]

const createRouter = () => new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes: commonList
})

const router = createRouter();

//路由守卫 建议可以直接抽出去作为权限校验
router.beforeEach(async (to, from, next) => {
    //这里需要加入的vuex中把权限进行缓存 然后获取  不然这里会有问题
    var arr = Object.keys(to);
    // if (arr.length == 0) {
    //     console.log('tossssKKK', to)
    //     next('/home');
    // }
    if (to.path == '/login' || to.path == '/home') {
        next();
    }
    if (to.matched.length == 0) {
        // next('/home'); // 否则全部重定向到登录页
        let data = JSON.parse(window.sessionStorage.getItem("routeList"));
        if (data == '[]' || data == '[空白]') {}
        resetRouter(data);
        next({ ...to, replace: true })
    } else {
        next();
    }


})
//重新处理路由
export function resetRouter(routerList) {
    if (routerList == null) {
        //重新加载路由即可
       return router.matcher = new VueRouter({
            routes: commonList
        }).matcher
    }
    //设置获取路由结构
    const newRouter = buildRouter(routerList)
    //将加载的路由重新放到/home下面 形成子路由模式
    commonList[2].children = newRouter
    const routerAll = commonList
    //重新加载路由即可
    router.matcher = new VueRouter({
        routes: routerAll
    }).matcher
}

function buildRouter(list) {
    const routerList = []
    list.forEach(element => {
        if (element.type == 2 || element.type == 1) {
            const routerVO = {
                path: element.path == null ? '' : element.path,
                component: () => import('@/views/' + element.component),
                children: buildChildren(routerList, element.children)
            }
            routerList.push(routerVO)
        }
    });
    return routerList;
}

//处理子菜单
function buildChildren(routerList, childrenList) {
    if (childrenList == '[]' || childrenList == undefined || childrenList == '[空白]') {
        return
    }

    childrenList.forEach(vo => {
        if (vo.type = 2) {
            const childRoute = {
                path: vo.path,
                component: () => import('@/views/' + vo.component),
                children: buildChildren(routerList, vo.children)
            }
            routerList.push(childRoute)
        }
    })
}
// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(err => err)
}

export default router