// 回显数据字典
export function selectDictLabel(datas, value) {
	var actions = [];
	Object.keys(datas).some((key) => {
		if (datas[key].value == ('' + value)) {
			actions.push(datas[key].text);
			return true;
		}
	})
	return actions.join('');
}

// 回显数据字典（字符串数组）
export function selectDictLabels(datas, value, separator) {
	var actions = [];
	var currentSeparator = undefined === separator ? "," : separator;
	var temp = value.split(currentSeparator);
	Object.keys(value.split(currentSeparator)).some((val) => {
		Object.keys(datas).some((key) => {
			if (datas[key].value == ('' + temp[val])) {
				actions.push(datas[key].dictLabel + currentSeparator);
			}
		})
	})
	return actions.join('').substring(0, actions.join('').length - 1);
}

//时间戳转时间
export function timeStamp(time) {
	var date = new Date(time * 1000);
	var Y = date.getFullYear() + '-';
	var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	var D = date.getDate() + ' ';
	var h = date.getHours() + ':';
	var m = date.getMinutes() + ':';
	var s = date.getSeconds();
	if (D < 10) {
		D = "0" + D;
	}
	return Y + M + D
}

//时间转时间戳  有问题呢
export function timeChange(index) {
	var strtime = index;
	var date = new Date(strtime);
	var time = Date.parse(date) / 1000;
	return time
}

//时间转成工具 将秒转成时间格式显示
export function changSecondToDate(msd) {
	var time = msd;
	if (null != time && "" != time) {
		if (time > 60 && time < 60 * 60) {
			time =
				parseInt(time / 60.0) +
				"分钟" +
				parseInt((parseFloat(time / 60.0) - parseInt(time / 60.0)) * 60) +
				"秒";
		} else if (time >= 60 * 60 && time < 60 * 60 * 24) {
			time =
				parseInt(time / 3600.0) +
				"小时" +
				parseInt(
					(parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
				) +
				"分钟" +
				parseInt(
					(parseFloat(
						(parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
					) -
						parseInt(
							(parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
						)) *
					60
				) +
				"秒";
		} else if (time >= 60 * 60 * 24) {
			time =
				parseInt(time / 3600.0 / 24) +
				"天" +
				parseInt(
					(parseFloat(time / 3600.0 / 24) - parseInt(time / 3600.0 / 24)) *
					24
				) +
				"小时" +
				parseInt(
					(parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
				) +
				"分钟" +
				parseInt(
					(parseFloat(
						(parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
					) -
						parseInt(
							(parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
						)) *
					60
				) +
				"秒";
		} else {
			time = parseInt(time) + "秒";
		}
	}
	return time;
}


//计算二个时间差多少
export function DateDifference(faultDate, completeTime) {
	// let d1 = new Date(faultDate);
	// let d2 = new Date(completeTime);
	var stime = new Date(faultDate).getTime();
	var etime = new Date(completeTime).getTime();
	var usedTime = etime - stime;  //两个时间戳相差的毫秒数
	var days = Math.floor(usedTime / (24 * 3600 * 1000));
	//计算出小时数
	var leave1 = usedTime % (24 * 3600 * 1000);    //计算天数后剩余的毫秒数
	var hours = Math.floor(leave1 / (3600 * 1000));
	//计算相差分钟数
	var leave2 = leave1 % (3600 * 1000);        //计算小时数后剩余的毫秒数
	var minutes = Math.floor(leave2 / (60 * 1000));
	// var time = days + "天"+hours+"时"+minutes+"分";
	var time = days;
	return time;
}

// DateDiff(sDate1, sDate2) {
// 	//sDate1和sDate2是2006-12-18格式
// 	var aDate, oDate1, oDate2, iDays;
// 	aDate = sDate1.split("-");
// 	oDate1 = new Date(aDate[0], aDate[1], aDate[2]); //转换为12-18-2006格式
// 	aDate = sDate2.split("-");
// 	oDate2 = new Date(aDate[0], aDate[1], aDate[2]);
// 	iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24)+1; //把相差的毫秒数转换为天数
// 	return iDays;
//   }


//判断是移动端还是pc端
export function isMobile() {
	let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
	return flag
}