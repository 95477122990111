import Vue from 'vue'
import App from './App.vue'
// import router from './router'
import router from './router/router'
import './plugins/element.js'
import '@/assets/css/global.css'
import axios from 'axios'
//使用字典书籍进行回显
import {  selectDictLabel, selectDictLabels,timeStamp,timeChange,changSecondToDate,isMobile } from "@/utils/xun";
//全局查询字字典的数据
import { selectDictItemSyn } from "@/api/common/foreign"
import dayjs from "dayjs"
import echarts from 'echarts'
// 分页组件
import Pagination from "@/components/pagination";
// 导入moment.ja
import moment from 'moment'
 
// import isMobile from '@/utils/isMobile'
Vue.prototype.$isMobile = isMobile // 用$与组件内自定义的函数区分，其他符号也可以



// 定位中国使用中文
moment.locale('zh-cn')

Vue.component('Pagination', Pagination)
Vue.prototype.$moment = moment;
Vue.prototype.dayjs = dayjs;//可以全局使用dayjs
Vue.prototype.selectDictItemSyn = selectDictItemSyn
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.timeStamp = timeStamp
Vue.prototype.timeChange = timeChange
Vue.prototype.changSecondToDate=changSecondToDate
Vue.prototype.$http = axios
//绑定echanrts
Vue.prototype.$echarts = echarts

// 定义根路径
// axios.defaults.baseURL='http://localhost:10011/xun/'
axios.changeOrigin = true
Vue.prototype.$http = axios
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
